import jwt from 'jsonwebtoken';

export const GraficoRender = ({ graficoId, graficoNome }) => {

    const returnUrl = () => {
        const token = jwt.sign({
            resource: { dashboard: graficoId },
            params: {},
            exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minutes
        }, process.env.REACT_APP_API_METABASE_SECRET_KEY);
        return process.env.REACT_APP_API_METABASE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";
    }

    return (
        <iframe src={returnUrl()} frameBorder="0" title={graficoNome} width="100%" height={900}></iframe>
    )
}
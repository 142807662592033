import React, { useCallback, useEffect, useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { ErrorValidationMessage } from './ErrorValidationMessage';
import { BrCalendar } from './BrCalendar';
import api from '../api/api';
import { InputNumber } from 'primereact/inputnumber';
import '../layout/sass/_form.scss';
import { FormFaturaItens } from './FormFaturaItens';
import { TabelaFaturaItens } from './TabelaFaturaItens';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

import { Dialog } from 'primereact/dialog';

export const FormFaturas = ({ isEditing, isLoading, isViewing, defaultValues }) => {
    const { id } = useParams();
    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [listItens, setListItens] = useState(false);
    const [listFornecedores, setListFornecedores] = useState(false);
    const [faturaItem, setFaturaItem] = useState([]);
    const [faturaId, setFaturaId] = useState(null);
    const schema = Yup.object().shape({
        // instalacaoId: Yup
        //     .number()
        //     .required("A instalacao é obrigatória"),
        fornecedorId: Yup
            .number()
            .required("O fornecedor é obrigatório"),
        dataEmissao: Yup
            .date()
            .typeError("Uma data deve ser inserida")
            .required("A data de emissão é obrigatória"),
        // dataLiquidacao: Yup
        //     .date()
        //     .typeError("Uma data deve ser inserida")
        //     .required("A data de liquidação é obrigatória"),
        dataVencimento: Yup
            .date()
            .typeError("Uma data deve ser inserida")
            .required("A data de vencimento é obrigatória"),
        numero: Yup
            .string()
            .required("O número é obrigatório"),
        valorTotal: Yup
            .number()
            .required("O valor total é obrigatório"),
        valorRetencao: Yup
            .number()
            .required("O valor retenção é obrigatório"),

    })

    const [showIncluirItens, setShowIncluirItens] = useState(false);

    const buttonText = () => {
        if (isViewing) {
            return "Voltar";
        }
        if (isEditing) {
            return "Atualizar";
        }
        return "Salvar";
    }

    const [isDialogVisible, setDialogVisible] = useState(false);
    const openDialog = () => {
        setDialogVisible(true);
    };

    const closeDialog = () => {        
        setDialogVisible(false);        
    };


    const formik = useFormik({

        initialValues: defaultValues,

        validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {

            setIsSubmiting(true)

            data.faturaItem = faturaItem

            try {
                if (!isEditing) {

                    const response = await api.post("faturas", data)
                    const { id } = response.data;

                    setFaturaId(id);

                    setShowIncluirItens(true);

                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `Faturas adicionada com sucesso` });


                }
                else if (!isViewing) {

                    await api.put("faturas", data)

                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `Faturas atualizada com sucesso` });
                    setTimeout(() => {

                        history.push('/faturas')

                    }, 2000)
                } else {
                    history.push('/faturas');
                }
            }
            catch (error) {
                if (!isEditing)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar o faturas!', detail: error.toString() });
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar o faturas!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const handleGetItens = useCallback(async () => {

        await api.get("instalacao/instalacaoAll").then((response) => {
            const itens = response.data.map(item => {
                return {
                    id: item.id,
                    numeroInstalacao: item.numeroInstalacao
                }
            });

            setListItens(itens);
        });

    }, [])

    const handleGetFornecedores = useCallback(async () => {

        await api.get("fornecedor/fornecedorAll").then((response) => {
            const fornecedors = response.data.map(item => {
                return {
                    id: item.id,
                    nome: item.nome
                }
            });

            setListFornecedores(fornecedors);
        });

    }, [])

    useEffect(async () => {

        await handleGetItens();
        await handleGetFornecedores();

    }, [handleGetItens, handleGetFornecedores]);

    useEffect(() => {
        const fetchData = async () => {
            if (isEditing) {
                const response = await api.get(`faturaItem/faturaId/${id}`);
                setFaturaId(id);
    
                response.data.content.forEach(faturaItem => {
                    setFaturaItem(faturaItens => [...faturaItens, faturaItem]);
                });
            }
    
            const respSomaFaturas = await api.get(`faturaItem/somaFaturas/${id}`);
            const { valorTotal, valorRetencao } = respSomaFaturas.data;
    
            const totalGeral = (valorTotal + valorRetencao).toFixed(2);
    
            // Verifique se `defaultValues` está carregado antes de continuar
            if (defaultValues && defaultValues.valorTotal !== undefined && defaultValues.valorRetencao !== undefined) {
                const defaultTotalGeral = (defaultValues.valorTotal + defaultValues.valorRetencao).toFixed(2);                
    
                // Verifique a diferença entre os totais
                if (totalGeral !== defaultTotalGeral) {
                    setShowIncluirItens(true);
                } else {
                    setShowIncluirItens(false);
                }
            }
        };
    
        fetchData();
    }, [id, isEditing, defaultValues]); // Inclua `defaultValues` nas dependências para garantir que o efeito seja executado quando ele mudar.
    

    return (
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <h2 className="p-mt-0">Faturas</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">

                        {/* <div className="p-field p-col-12 p-sm-3">
                            <label
                                htmlFor="instalacaoId"
                                className={classNames({ 'p-error': isFormFieldValid('instalacaoId') })}
                            >
                                Instalações
                            </label>
                            {

                                !isLoading ?
                                    <Dropdown
                                        id="instalacaoId"
                                        name="instalacaoId"
                                        options={listItens}
                                        filter={true}
                                        emptyMessage={"Nenhuma instalacao encontrada"}
                                        optionLabel="numeroInstalacao"
                                        optionValue="id"
                                        placeholder="Selecione uma instalacao"
                                        className={classNames({ 'p-invalid': isFormFieldValid('instalacaoId') })}
                                        value={formik.values.instalacaoId}
                                        onChange={option => formik.setFieldValue("instalacaoId", option.value)}
                                        emptyFilterMessage={"Nenhuma instalacao encontrada"}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.instalacaoId && formik.touched.instalacaoId &&
                                <ErrorValidationMessage message={formik.errors.instalacaoId} />
                            }
                        </div> */}

                        <div className="p-field p-col-12 p-sm-2">
                            <label
                                htmlFor="fornecedorId"
                                className={classNames({ 'p-error': isFormFieldValid('fornecedorId') })}
                            >
                                Fornecedores ..
                            </label>
                            {

                                !isLoading ?
                                    <Dropdown
                                        id="fornecedorId"
                                        name="fornecedorId"
                                        options={listFornecedores}
                                        filter={true}
                                        emptyMessage={"Nenhum fornecedor encontrada"}
                                        optionLabel="nome"
                                        optionValue="id"
                                        placeholder="Selecione um fornecedor"
                                        className={classNames({ 'p-invalid': isFormFieldValid('fornecedorId') })}
                                        value={formik.values.fornecedorId}
                                        onChange={option => formik.setFieldValue("fornecedorId", option.value)}
                                        emptyFilterMessage={"Nenhum fornecedor encontrada"}
                                        disabled={isViewing}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.fornecedorId && formik.touched.fornecedorId &&
                                <ErrorValidationMessage message={formik.errors.fornecedorId} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-3 p-md-2">
                            <label
                                htmlFor="dataEmissao"
                                className={classNames({ 'p-error': isFormFieldValid('dataEmissao') })}
                            >
                                Data de Emissão
                            </label>
                            <BrCalendar
                                id="dataEmissao"
                                name="dataEmissao"
                                value={formik.values.dataEmissao}
                                onChange={formik.handleChange}
                                placeholder={formik.values.dataEmissao != null && isEditing ? new Date(formik.values.dataEmissao).toLocaleDateString('pt-BR') : ""}
                                className={classNames({ 'p-invalid': isFormFieldValid('dataEmissao') })}
                                yearRange={`2000:${new Date().getFullYear()}`}
                                disabled={isViewing}
                            />
                            {formik.errors.dataEmissao && formik.touched.dataEmissao &&
                                <ErrorValidationMessage message={formik.errors.dataEmissao} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-3 p-md-2">
                            <label
                                htmlFor="dataLiquidacao"
                                className={classNames({ 'p-error': isFormFieldValid('dataLiquidacao') })}
                            >
                                Data de Liquidação
                            </label>
                            <BrCalendar
                                id="dataLiquidacao"
                                name="dataLiquidacao"
                                value={formik.values.dataLiquidacao}
                                onChange={formik.handleChange}
                                placeholder={formik.values.dataLiquidacao != null && isEditing ? new Date(formik.values.dataLiquidacao).toLocaleDateString('pt-BR') : ""}
                                className={classNames({ 'p-invalid': isFormFieldValid('dataLiquidacao') })}
                                yearRange={`2000:${new Date().getFullYear()}`}
                                disabled={isViewing}
                            />
                            {formik.errors.dataLiquidacao && formik.touched.dataLiquidacao &&
                                <ErrorValidationMessage message={formik.errors.dataLiquidacao} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-3 p-md-2">
                            <label
                                htmlFor="dataVencimento"
                                className={classNames({ 'p-error': isFormFieldValid('dataVencimento') })}
                            >
                                Data de Vencimento
                            </label>
                            <BrCalendar
                                id="dataVencimento"
                                name="dataVencimento"
                                value={formik.values.dataVencimento}
                                onChange={formik.handleChange}
                                placeholder={formik.values.dataVencimento != null && isEditing ? new Date(formik.values.dataVencimento).toLocaleDateString('pt-BR') : ""}
                                className={classNames({ 'p-invalid': isFormFieldValid('dataVencimento') })}
                                yearRange={`2000:${new Date().getFullYear()}`}
                                disabled={isViewing}
                            />
                            {formik.errors.dataVencimento && formik.touched.dataVencimento &&
                                <ErrorValidationMessage message={formik.errors.dataVencimento} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-2">
                            <label
                                htmlFor="numero"
                                className={classNames({ 'p-error': isFormFieldValid('numero') })}
                            >
                                Número
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="numero"
                                        name="numero"
                                        type="text"
                                        className={classNames({ 'p-invalid': isFormFieldValid('numero') })}
                                        value={formik.values.numero}
                                        onChange={formik.handleChange}
                                        disabled={isViewing}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.numero && formik.touched.numero &&
                                <ErrorValidationMessage message={formik.errors.numero} />
                            }
                        </div>

                        <div className="p-field p-col-12 p-sm-2">
                            <label htmlFor="valorTotal" className={classNames({ "p-error": isFormFieldValid("valorTotal") })}>
                                Valor Total dos Serviços
                            </label>
                            {
                                !isLoading ?
                                    <InputNumber
                                        id="valorTotal"
                                        name="valorTotal"
                                        mode="currency" minFractionDigits={2}
                                        currency="BRL"
                                        className={classNames({ 'p-invalid': isFormFieldValid('valorTotal') })}
                                        locale="pt-BR"
                                        value={formik.values.valorTotal}
                                        onChange={(e) => formik.setFieldValue("valorTotal", e.value)}
                                        disabled={isViewing}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.valorTotal && formik.touched.valorTotal && <ErrorValidationMessage message={formik.errors.valorTotal} />}
                        </div>

                        <div className="p-field p-col-12 p-sm-2">
                            <label htmlFor="valorRetencao" className={classNames({ "p-error": isFormFieldValid("valorRetencao") })}>
                                Valor Retenção
                            </label>
                            {
                                !isLoading ?
                                    <InputNumber
                                        id="valorRetencao"
                                        name="valorRetencao"
                                        mode="currency" minFractionDigits={2}
                                        currency="BRL"
                                        className={classNames({ 'p-invalid': isFormFieldValid('valorRetencao') })}
                                        locale="pt-BR"
                                        value={formik.values.valorRetencao}
                                        onChange={(e) => formik.setFieldValue("valorRetencao", e.value)}
                                        disabled={isViewing}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.valorRetencao && formik.touched.valorRetencao && <ErrorValidationMessage message={formik.errors.valorRetencao} />}
                        </div>

                    </div>

                    <br />



                    <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            !isLoading ?
                                <Button
                                    label={buttonText()}
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmiting}
                                />
                                :
                                <Skeleton width="82.16px" height="35px" />
                        }
                    </div>
                </form>



                {showIncluirItens &&
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                        <Button label="Incluir Itens" onClick={openDialog} />
                    </div>
                }

                <Dialog
                    header="Formulário Incluir Item"
                    visible={isDialogVisible}
                    onHide={closeDialog}
                    breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                    style={{ width: '75vw' }}
                >
                    {showIncluirItens && (
                        <FormFaturaItens
                            isEditing={isEditing}
                            isLoading={isLoading}
                            setFaturaItem={setFaturaItem}
                            faturaId={faturaId}
                        />
                    )}
                </Dialog>


                <div className="p-d-flex p-jc-end p-mr-1">
                    <TabelaFaturaItens
                        isEditing={isEditing}
                        atualizarFaturaItens={setFaturaItem}
                        faturaItem={faturaItem}
                    />
                </div>

            </div>
        </div>

    );
}

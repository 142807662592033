import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';
import { InputNumber } from 'primereact/inputnumber';

export const TabelaFaturaItens = ({ isEditing, atualizarFaturaItens, faturaItem }) => {
    const toast = useRef(null);
    const [faturaItens, setFaturaItens] = useState([]);
    const [totais, setTotais] = useState([]);
    const [instalacoes, setInstalacoes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [sortable, setSortable] = useState('ASC');
    const [totalRecords, setTotalRecords] = useState(0);
    const [sortableCampo, setSortableCampo] = useState('id');
    const confirmationToast = useRef(null)
    const [dt, setDt] = useState(null);
    const { id } = useParams();
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 20,
        sortField: null,
        sortOrder: null,
        filters: {}
    });
    const history = useHistory();

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);


    useEffect(() => {
        const fetchFaturaItens = async () => {
            try {
                await getFaturaItens();
                await getTotais();

            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchFaturaItens();

    }, [])

    const getFaturaItens = useCallback(async (event) => {
        setIsLoading(true);
        setFaturaItens([]);

        const startIndex = event ? event.first : 0;  // Iniciar de 0 caso o evento seja indefinido
        setFirst(startIndex);  // Atualize a página corrente

        // Construa os filtros com base no estado lazyParams
        const filters = lazyParams.filters || {};

        try {
            if (isEditing) {
                const page = event ? event.page : 0;  // Obtenha o número da página corretamente

                // Convertendo filtros para query params
                const filterParams = Object.keys(filters).map(key => {
                    return `${key}=${filters[key].value}`;
                }).join('&');

                // Monta a URL com filtros, página e ordenação
                const url = `faturaItem/faturaId/${id}?page=${page}&sort=${sortableCampo},${sortable}${filterParams ? '&' + filterParams : ''}`;

                const response = await api.get(url);
                const data = response.data.content;
                setFaturaItens(data);

                const respElements = await api.get(`faturaItem/faturaId/${id}?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
        }
        setIsLoading(false);
    }, [sortable, sortableCampo, isEditing, id, lazyParams.filters]);  // Adicione lazyParams.filters à lista de dependências


    useEffect(async () => {
        // Chama a função getFaturaItens sempre que o sortable ou sortableCampo mudarem
        await getFaturaItens();
        await getTotais();
    }, [sortable, sortableCampo, lazyParams, faturaItem]);  // A função será chamada quando sortable ou sortableCampo mudarem



    const getTotais = async () => {
        setIsLoading(true)
        setTotais([])

        try {

            if (isEditing) {

                const respSomaFaturas = await api.get(`faturaItem/somaFaturas/${id}`);

                setTotais(respSomaFaturas.data)

            }

        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter faturas', detail: error.toString() });
        }

        setIsLoading(false)
    }


    const actionBodyTemplate = (rowData) => {

        return (
            <div className="actions">

                <Button
                    type="button"
                    icon="pi pi-pencil"
                    className="p-button-rounded p-mr-2 p-button-edit"
                    loading={isDeleting}
                    onClick={() => handleEditClick(rowData)}
                />

                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-rounded p-mr-2 p-button-danger"
                    loading={isDeleting}
                    onClick={() => handleDeleteClick(rowData)}
                />


            </div>
        );
    }

    const itemIdBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Item</span>
                    <span className="table-data">{rowData.id ? rowData.id : ""}</span>
                </p>
            </React.Fragment>
        );
    }

    const instalacaoIdBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textAlign: 'left' }}>
                    <span className="p-column-title">Instalação</span>
                    <span className="table-data">{rowData.instalacao?.numeroInstalacao || ""}</span>
                </p>
            </React.Fragment>
        );
    };




    const descricaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Descrição Fatura</span>
                    <span className="table-data">{rowData.instalacao.descricao ? rowData.instalacao.descricao : ""}</span>
                </p>
            </React.Fragment>
        );
    }



    const enderecoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Endereço</span>
                    <span className="table-data">{rowData.instalacao.endereco ? rowData.instalacao.endereco : ""}</span>
                </p>
            </React.Fragment>
        );
    }

    const empenhoBodyTemplate = (rowData) => {

        const listInstalacaoEmpenho = rowData.instalacao.listInstalacaoEmpenho;

        const ativos = listInstalacaoEmpenho.filter(item => item.ativo === true);


        return (
            <React.Fragment>
                <p style={{
                    display: 'flex',
                    alignItems: 'center', // Alinha o conteúdo verticalmente no centro
                    justifyContent: 'flex-start', // Alinha o conteúdo na direção horizontal
                    textAlign: 'left',
                }}>
                    <span className="p-column-title">Empenho</span>
                    <span className="table-data" style={{ marginLeft: '10px' }}>
                        {/* Aqui, caso a lista de empenhos ativas não esteja vazia, exibe a lista */}
                        {ativos.length > 0 ? (
                            <span>Sim</span>
                        ) : (
                            <span>Não</span>
                        )}
                    </span>
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-mr-2 edit-btn"
                        style={{ marginLeft: '10px' }} // Adiciona espaço entre o botão e o conteúdo anterior
                        onClick={() => handleEditInstalacaoClick(rowData)}
                    />
                </p>
            </React.Fragment>

        );
    }

    const quantidadeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Quantidade</span>
                    <span className="table-data">{rowData.quantidade ? rowData.quantidade.toLocaleString('pt-BR') : ""}</span>
                </p>
            </React.Fragment>
        );
    }

    const unidadeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Unidade</span>
                    <span className="table-data">{rowData.unidade ? rowData.unidade : ""}</span>
                </p>
            </React.Fragment>
        );
    }

    const valorTotalBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Valor Total</span>
                    <span className="table-data">{rowData.valorTotal ? rowData.valorTotal.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    }) : ""}</span>
                </p>
            </React.Fragment>
        );
    }



    const valorRetencaoBodyTemplate = (rowData) => {


        const totalValorLancamento = rowData.faturaItemDetalhes.reduce((accumulator, item) => {
            return accumulator + (item.valorLancamento || 0); // Adiciona o valorLancamento ou 0 se for indefinido
        }, 0);

        const retencao = (totalValorLancamento - rowData.valorTotal) / 2;

        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Valor Unitario</span>
                    <span className="table-data">{retencao ? retencao.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    }) : ""}</span>
                </p>
            </React.Fragment>
        );
    }

    const handleEditClick = (data) => {
        setModalData(data); // Definir os dados que serão exibidos no modal
        setShowModal(true);  // Mostrar o modal
    }

    const handleHide = () => {
        setShowModal(false);
    }

    const handleDeleteClick = (data) => {

        let tempFaturas = [];
        confirmDialog({
            message: `Você tem certeza que quer remover ${data.descricaoFatura}?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: async () => {
                setIsDeleting(true);

                try {

                    const response = await api.delete(`faturaItem/${data.id}`);

                    setFaturaItens(faturaItens.filter(item => item !== data));
                    faturaItens.filter(item => item !== data).forEach(item => {
                        tempFaturas.push(item.muda)
                    });
                    toast.current.show({ life: 5000, severity: 'success', summary: 'Remoção concluída!', detail: `Instalação ${data.descricao} removida com sucesso` });

                }
                catch (error) {
                    toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover a muda', detail: error.response.data });
                }

                setIsDeleting(false);
            }

        });
    }

    useEffect(() => {

        

    }, [faturaItens]);

    const onRowEditComplete1 = (e) => {
        let faturaItens2 = [...faturaItens];
        let { newData, index } = e;

        faturaItens2[index] = newData;

        setFaturaItens(faturaItens2);
    }

    const handleEditInstalacaoClick = (data) => {
        history.push(`/instalacao/edit/${data.instalacao.id}`, {
            from: `/faturas/edit/${id}`
        });
    };

    const handleEdit = (data, campo, value) => {
        data[campo] = value;
        atualizarFaturaItens(faturaItens);
    }

    const editarQuantidade = (options) => {

        return <InputNumber value={options.rowData.quantidade} onValueChange={((e) => { handleEdit(options.rowData, 'quantidade', e.value) })} mode="decimal" minFractionDigits={2} maxFractionDigits={2} currency="BRL" locale="pt-BR" />

    }

    const onFilter = (event) => {
        // Atualiza os parâmetros de filtro no estado lazyParams
        setLazyParams({
            ...lazyParams,
            filters: event.filters
        });

        // Sempre começa a partir da primeira página ao aplicar o filtro
        event.first = 0;
        setLazyParams(event);
    }

    const onSort = (event) => {

        const newSortField = event.sortField === "instalacaoId" ? "instalacao.id" : event.sortField;

        const newSortOrder = sortable === 'ASC' ? 'DESC' : 'ASC';  // Ordenação crescente ou decrescente

        setSortableCampo(newSortField);
        setSortable(newSortOrder);

        // Atualiza os parâmetros de busca com o novo campo e ordem
        setLazyParams((prevParams) => ({
            ...prevParams,
            sortField: newSortField,
            sortOrder: newSortOrder,
        }));
    };


    const formatarMoeda = (valor) => {
        return valor ? valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00';
    };


    return (

        <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

            <Toast ref={toast} position="bottom-right" />

            <Toast ref={confirmationToast} position="bottom-center" />

            <div className="card p-col-12 datatable-responsive-demo">

                <div className="p-fluid">

                    <DataTable
                        totalRecords={totalRecords}
                        lazy
                        first={first}
                        onPage={getFaturaItens}  // Esta chamada vai passar o evento para o getFaturaItens
                        filterDisplay="row"
                        loading={isLoading}
                        editMode="row"
                        onRowEditComplete={onRowEditComplete1}
                        responsiveLayout="scroll"
                        value={faturaItens}
                        className="p-datatable-responsive-demo"
                        emptyMessage="Nenhuma fatura encontrada"
                        dataKey="id"
                        paginator
                        rows={20}
                        currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} faturas`}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        onSort={onSort}
                        onFilter={onFilter}
                        filters={lazyParams.filters}
                    >



                        {/* <Column
                            field="itemId"
                            header="Item"
                            footer=""
                            sortable
                            body={itemIdBodyTemplate}
                            style={{ textAlign: "center" }}
                        /> */}

                        <Column
                            field="instalacao.numeroInstalacao"  // Ajuste para acessar o campo correto
                            header="Instalação"
                            footer=" "
                            sortable
                            body={instalacaoIdBodyTemplate}
                            style={{ textAlign: "center" }}
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder="Pesquisar por Instalação"
                        />

                        {/* <Column
                            field="instalacaoId"
                            header="Instalação"
                            footer=" "
                            sortable
                            body={instalacaoIdBodyTemplate}
                            style={{ textAlign: "center" }}
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder="Pesquisar por Instalação"

                        /> */}


                        <Column
                            field="descricao"
                            header="Descrição"
                            footer=" "
                            sortable
                            body={descricaoBodyTemplate}
                            style={{ textAlign: "center" }}
                        />

                        <Column
                            field="endereco"
                            header="Enderecço"
                            footer=" "
                            sortable
                            body={enderecoBodyTemplate}
                            style={{ textAlign: "center" }}
                        />

                        <Column
                            field="empenho"
                            header="Empenho"
                            footer=" "
                            sortable
                            body={empenhoBodyTemplate}
                            style={{ textAlign: "center" }}
                        />

                        <Column
                            field="quantidade"
                            header="Quantidade"
                            footer="Total da Fatura:"
                            sortable
                            body={quantidadeBodyTemplate}
                            // editor={(options) => editarQuantidade(options)}
                            style={{ width: '10%' }}
                        />

                        {/* <Column
                            rowEditor
                            headerStyle={{ width: '10%', minWidth: '8rem' }}
                            bodyStyle={{ textAlign: 'center' }}
                            footer=" "
                            style={{ textAlign: "center" }}>

                        </Column> */}

                        {/* <Column
                            field="unidade"
                            header="Unidade"
                            footer=" "
                            sortable
                            body={unidadeBodyTemplate}
                            style={{ textAlign: "center" }}
                        /> */}

                        <Column
                            field="valorTotal"
                            header="Valor Total"
                            footer={formatarMoeda(totais.valorTotal)}
                            sortable
                            body={valorTotalBodyTemplate}
                            style={{ textAlign: "center" }}
                        />

                        <Column
                            field="valorRetencao"
                            header="Retenção"
                            footer={formatarMoeda(totais.valorRetencao)}
                            sortable
                            body={valorRetencaoBodyTemplate}
                            style={{ textAlign: "center" }}
                        />

                        <Column
                            header="Ações"
                            body={actionBodyTemplate}

                        />


                    </DataTable>

                </div>
            </div>
            <Dialog
                header="Detalhes da Instalação"
                visible={showModal}
                onHide={handleHide}
                style={{ width: '70vw' }}
            >
                {modalData && (
                    <div>
                        <h5>Instalação: {modalData.instalacao.numeroInstalacao}</h5>
                        <p><strong>Endereço:</strong> {modalData.instalacao.endereco}</p>
                        <p><strong>Medidor:</strong> {modalData.instalacao.medidor}</p>
                        <p><strong>Responsável:</strong> {modalData.instalacao.responsavel || 'Não informado'}</p>
                        <p><strong>Quantidade:</strong> {modalData.quantidade}</p>
                        <p><strong>Valor Unitário:</strong> {modalData.valorUnitario ? modalData.valorUnitario.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        }) : ""}</p>
                        <p><strong>Valor Total:</strong> R$ {modalData.valorTotal ? modalData.valorTotal.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        }) : ""}</p>

                        <h3>Detalhamento da Instalação na Fatura</h3>
                        <DataTable value={modalData.faturaItemDetalhes} paginator rows={10} rowsPerPageOptions={[5, 10, 20]}>
                            <Column field="codLancamento" header="Código" sortable style={{ width: '8%' }} />
                            <Column field="dataLancamento" header="Data" sortable style={{ width: '8%' }} />
                            <Column field="descLancamento" header="Descrição" sortable style={{ width: '35%' }} />
                            <Column field="valorLancamento" header="Valor" body={(rowData) => `R$ ${rowData.valorLancamento.toFixed(2)}`} sortable style={{ width: '8%' }} />
                        </DataTable>
                    </div>
                )}
            </Dialog>

        </div >

    );
}

import React, { useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Printd } from 'printd';
import { Toast } from 'primereact/toast';
import api from '../api/api';

export const DialogLiquidar = ({ visible, setVisible, liquidar, simular, setLazyParams }) => {
    const d = new Printd();
    const toast = useRef(null);
    const cssText = `
    #print-content {
        font-family: 'Open Sans', sans-serif;
        width: 100%;
        text-align: left;
    }
    
    img {
            margin-top: 25px;
            width: 100%;
            max-width: 250px;
            height: auto;
            display: block;
        }
        
        .header-img {
            display: flex;
            justify-content: center;
        }

        .header {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        .header h2 {
            margin-bottom: 0px;
        }

        .header h4 {
            margin-top: 7px;
        }
    `

    const listaLiquidar = liquidar.map((data) => {        

        // Função para calcular o subtotal por secretariaId
        const calculateSubtotalsBySecretaria = () => {
            const subtotals = {};

            data.liquidacoes.forEach(item => {
                const secretariaId = item.secretariaId;
                if (!subtotals[secretariaId]) {
                    subtotals[secretariaId] = {
                        totalSaldoAtual: 0,
                        totalValorALiquidar: 0,
                        totalValorRetencao: 0,
                    };
                }
                subtotals[secretariaId].totalSaldoAtual += item.saldoAtual || 0;
                subtotals[secretariaId].totalValorALiquidar += item.valorALiquidar || 0;
                subtotals[secretariaId].totalValorRetencao += item.valorRetencao || 0;
            });

            return subtotals;
        };

        // Ordenando as liquidações primeiro por secretariaId e depois por numeroEmpenho
        const sortedLiquidacoes = data.liquidacoes.sort((a, b) => {
            if (a.secretariaId === b.secretariaId) {
                return a.numeroEmpenho - b.numeroEmpenho;
            }
            return a.secretariaId - b.secretariaId;
        });

        const renderLiquidacoesRows = () => {
            const subtotals = calculateSubtotalsBySecretaria();
            const liquidacoes = [];
            let lastSecretariaId = null;

            sortedLiquidacoes.forEach((item, index) => {
                // Se for uma nova secretaria, adiciona as linhas das liquidações anteriores e depois o subtotal da secretaria
                if (lastSecretariaId !== item.secretariaId && lastSecretariaId !== null) {
                    // Adiciona o subtotal da secretaria anterior
                    const subtotal = subtotals[lastSecretariaId];
                    liquidacoes.push(
                        <tr key={`subtotal-${lastSecretariaId}`}>
                            <td colSpan={2} style={{ textAlign: 'right' }}><b>Subtotal Secretaria {lastSecretariaId}:</b></td>
                            <td align='center'><b>R$ {subtotal.totalSaldoAtual.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></td>
                            <td align='center'><b>R$ {subtotal.totalValorALiquidar.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></td>
                            <td align='center'><b>R$ {subtotal.totalValorRetencao.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></td>
                        </tr>
                    );
                }

                const usoEmpenho = (((item.valorEmpenho - item.saldoAtual) + item.valorALiquidar)/item.valorEmpenho) * 100;                

                liquidacoes.push(                    
                    <tr key={index} style={{ color: usoEmpenho > 70 ? 'red' : 'black', fontWeight: usoEmpenho > 70 ? 'bold' : 'normal' }}>
                        <td width="10%" align='center'><span>{item.secretariaId}</span></td>
                        <td width="10%" align='center'><span>{item.numeroEmpenho} - {item.ficha} </span></td>
                        <td width="10%" align='center'><span>R$ {item.saldoAtual != undefined ? item.saldoAtual.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                        <td width="10%" align='center'><span>R$ {item.valorALiquidar != undefined ? item.valorALiquidar.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                        <td width="10%" align='center'><span>R$ {item.valorRetencao != undefined ? item.valorRetencao.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                        <td width="10%" align='center'><span>{usoEmpenho != undefined ? usoEmpenho.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                    </tr>
                );
                lastSecretariaId = item.secretariaId; // Atualiza a secretaria atual
            });

            // Após o último item, adiciona o subtotal da última secretaria
            if (lastSecretariaId !== null) {
                const subtotal = subtotals[lastSecretariaId];
                liquidacoes.push(
                    <tr key={`subtotal-${lastSecretariaId}`}>
                        <td colSpan={2} style={{ textAlign: 'right' }}><b>Subtotal Secretaria {lastSecretariaId}:</b></td>
                        <td align='center'><b>R$ {subtotal.totalSaldoAtual.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></td>
                        <td align='center'><b>R$ {subtotal.totalValorALiquidar.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></td>
                        <td align='center'><b>R$ {subtotal.totalValorRetencao.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></td>
                    </tr>
                );
            }

            const totalSaldoAtual = data.liquidacoes.reduce((total, item) => total + (item.saldoAtual || 0), 0);
            const totalValorRetencao = data.liquidacoes.reduce((total, item) => total + (item.valorRetencao || 0), 0);
            const totalValorALiquidar = data.liquidacoes.reduce((total, item) => total + (item.valorALiquidar || 0), 0);

            liquidacoes.push(
                <tr key="total">
                    <th colSpan={2} style={{ textAlign: 'right' }} ><i>Total Geral:</i></th>
                    <td align='center'><span><b>R$ {totalSaldoAtual.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></span></td>
                    <td align='center'><span><b>R$ {totalValorALiquidar.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></span></td>
                    <td align='center'><span><b>R$ {totalValorRetencao.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></span></td>
                </tr>
            );
            return liquidacoes;
        };

        const renderFaturasRows = () => {
            const faturas = data.faturas.map((item, index) => (
                <tr key={index}>
                    <td width="10%" align='center'><span>{item.numero}</span></td>
                    
                    <td width="10%" align='center'><span>R$ {item.valorLiquidado != undefined ? item.valorLiquidado.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                    <td width="10%" align='center'><span>R$ {item.valorRetencao != undefined ? item.valorRetencao.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                </tr>
            ));
            const totalValorRetencao = data.faturas.reduce((total, item) => total + (item.valorRetencao || 0), 0);
            const totalValorTotal = data.faturas.reduce((total, item) => total + (item.valorLiquidado || 0), 0);
            faturas.push(
                <tr key="total">
                    <th width="100px" ><i>Total:</i></th>
                    
                    <td align='center'><span><b>R$ {totalValorTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></span></td>
                    <td align='center'><span><b>R$ {totalValorRetencao.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></span></td>
                </tr>
            );
            return faturas;
        };

        const renderLiquidacoesQtdeRows = () => {
            const liquidacoes = data.liquidacoesQtde.map((item, index) => (
                <tr key={index}>
                    <td width="10%" align='center'><span>{item.numeroEmpenho}</span></td>
                    <td width="10%" align='center'><span>{item.descricaoEmpenho}</span></td>
                    <td width="10%" align='center'><span>{item.codigo}</span></td>
                    <td width="10%" align='center'><span>{item.qtdeEmp != undefined ? item.qtdeEmp.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                    <td width="10%" align='center'><span>{item.qtdeLiq != undefined ? item.qtdeLiq.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                    <td width="10%" align='center'><span>R$ {item.valorEmp != undefined ? item.valorEmp.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                    <td width="10%" align='center'><span>R$ {item.valorTotalEmp != undefined ? item.valorTotalEmp.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                    <td width="10%" align='center'><span>R$ {item.valorTotalFat != undefined ? item.valorTotalFat.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span></td>
                </tr>
            ));
            const totalValorEmpenhado = data.liquidacoesQtde.reduce((total, item) => total + (item.valorTotalEmp || 0), 0);
            const totalValorFaturado = data.liquidacoesQtde.reduce((total, item) => total + (item.valorTotalFat || 0), 0);
            liquidacoes.push(
                <tr key="total">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <th width="100px" ><i>Total:</i></th>
                    <td align='center'><span><b>R$ {totalValorEmpenhado.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></span></td>
                    <td align='center'><span><b>R$ {totalValorFaturado.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></span></td>
                </tr>
            );
            return liquidacoes;

        };

        return (
            <div>
                <h2>Faturas:</h2>
                <table width="100%" border="1" style={{ 'borderCollapse': 'collapse' }} cellPadding={3} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th width="100px" ><i>N° Fatura</i></th>
                            
                            <th width="100px" ><i>Valor Total</i></th>     
                            <th width="100px" ><i>Valor Retenção</i></th>                       
                        </tr>
                    </thead>
                    <tbody>
                        {renderFaturasRows()}
                    </tbody>
                </table>

                <h2>Liquidações por Valor:</h2>
                <table width="100%" border="1" style={{ 'borderCollapse': 'collapse' }} cellPadding={3} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th width="100px" ><i>Sec. Id</i></th>
                            <th width="100px" ><i>N° Empenho - Dotação</i></th>
                            <th width="100px" ><i>Saldo Atual Emp.</i></th>
                            <th width="100px" ><i>Valor a Liquidar</i></th>
                            <th width="100px" ><i>Valor Retenção</i></th>
                            <th width="100px" ><i>% Uso Empenho</i></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderLiquidacoesRows()}
                    </tbody>
                </table>

                {data.liquidacoesQtde &&

                    <>

                        <h2>Liquidações por Qtde:</h2>
                        <table width="100%" border="1" style={{ 'borderCollapse': 'collapse' }} cellPadding={3} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <th width="100px" ><i>N° Empenho</i></th>
                                    <th width="100px" ><i>Descrição</i></th>
                                    <th width="100px" ><i>Código</i></th>
                                    <th width="100px" ><i>Qtde Emp</i></th>
                                    <th width="100px" ><i>Qtde a Liq.</i></th>
                                    <th width="100px" ><i>Valor Un</i></th>
                                    <th width="100px" ><i>Valor Total Emp.</i></th>
                                    <th width="100px" ><i>Valor Total Fat.</i></th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderLiquidacoesQtdeRows()}
                            </tbody>
                        </table>

                    </>

                }

            </div>
        );
    });

    const handlePrintClick = () => {
        d.print(document.getElementById("print-content"), [cssText])
    }

    const handleLiquidar = () => {
        try {
            liquidar[0].faturas.forEach(async fatura => {
                let faturaCompleta = await api.get(`faturas/${fatura.id}`);
                faturaCompleta.data.dataLiquidacao = new Date().toISOString();
                await api.put(`faturas`, faturaCompleta.data);
            });
            liquidar[0].liquidacoes.forEach(async liquidacao => {
                let empenhoCompleto = await api.get(`empenho/${liquidacao.id}`);
                empenhoCompleto.data.valorLiquidado = liquidacao.valorALiquidar;
                await api.put(`empenho`, empenhoCompleto.data);
            });
            simular();
            setVisible(false);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Erro ao processar a liquidação', life: 3000 });
        }
    };

    const header = (
        <div>
            
        </div>
    );


    return (
        <Dialog header="Confirmar Liquidação" visible={visible} onHide={() => setVisible(false)} maximizable footer={<div></div>} style={{ width: '90vw' }}>
            <Button label="Imprimir" className="p-button-secondary" icon="pi pi-print" onClick={handlePrintClick} />
            <Toast ref={toast} />
            <div id="print-content">
                {listaLiquidar}
            </div>
        </Dialog>
    );
}

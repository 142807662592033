import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Fieldset } from 'primereact/fieldset';
import { useParams } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';

import api from '../api/api';

import '../layout/sass/_form.scss';

export const FormEmpenhoInstalacao = ({ isEditing, isLoading, setListInstalacaoEmpenho }) => {

    const { id } = useParams();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [listInstalacao, setListInstalacao] = useState(false);
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    

    const schema = Yup.object().shape({
        peso: Yup
            .number()
            .required("O peso é obrigatória"),
        instalacaoId: Yup
            .number()
            .required("O instalação é obrigatória"),

    })

    useEffect(() => {

        if (isEditing) {
            api.get(`empenho/${id}`).then((response) => {                
                
                formik.setFieldValue("empenhoId", response.data.empenhoId);
            });
        }

    }, []);
    

    const formik = useFormik({

        initialValues: {
            "ativo": false,
            "empenhoAnoRef": null,
            "empenhoId": null,  
            "instalacaoId":null,
            "instalacaoNumero":null,
            "numeroEmpenho":null,
            "peso": 1,
            "saldoEmpenho": null                          
        },

        validationSchema: schema,

        enableReinitialize: false,

        onSubmit: (data) => {

            console.log(data);

            if (isSubmiting) return;
            
            data.empenhoId = id;

            setIsSubmiting(true)

            setListInstalacaoEmpenho(listInstalacaoEmpenho => [...listInstalacaoEmpenho, data]);


            setIsSubmiting(false)


        }
    })

    const handleChangeInstalacao = (e) => {        

        formik.setFieldValue("instalacaoId", e.value);

    }

    const handleGetInstalacoes = useCallback(async () => {

        await api.get("instalacao/instalacaoAll").then((response) => {

            const instalacoes = response.data.map(item => {
                
                return {
                    id: item.id,
                    numeroInstalacao: item.numeroInstalacao,
                    descricao: item.descricao,
                    endereco: item.endereco,
                    localidade: item.localidade
                }
            });

            setListInstalacao(instalacoes);
        });

    }, [])

    useEffect(async () => {

        await handleGetInstalacoes();

    }, [handleGetInstalacoes]);

    const handleCheckboxChange = (event) => {
        formik.setFieldValue('ativo', event.target.checked);
    };

    return (

        <Fieldset legend="Instalações" toggleable  >
            <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
                <Toast ref={toast} position="bottom-right" />
                <div className="p-col-12 p-sm-12">

                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-formgrid p-grid p-fluid p-mx-0" >

                            <div className="p-field p-col-4 p-sm-4">
                                <label
                                    htmlFor="instalacaoId"
                                    className={classNames({ 'p-error': isFormFieldValid('instalacaoId') })}
                                >
                                    Instalações ....
                                </label>
                                {

                                    !isLoading ?
                                        <Dropdown
                                            id="instalacaoId"
                                            name="instalacaoId"
                                            options={listInstalacao}
                                            filter={true}
                                            emptyMessage={"Nenhum instalação encontrado"}
                                            optionValue="id"
                                            optionLabel='numeroInstalacao'
                                            placeholder="Selecione uma instalação"
                                            className={classNames({ 'p-invalid': isFormFieldValid('instalacaoId') })}
                                            value={formik.values.instalacaoId}
                                            onChange={handleChangeInstalacao}
                                            emptyFilterMessage={"Nenhum isntalação encontrada"}
                                            itemTemplate={(option) => (
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr key={option.id}>
                                                            <td style={{ width: '10%', padding: '3px' }}>{option.numeroInstalacao}</td>
                                                            <td style={{ width: '10%', padding: '3px' }}>{option.descricao}</td>
                                                            <td style={{ width: '10%', padding: '3px' }}>{option.endereco}</td>
                                                            <td style={{ width: '10%', padding: '3px' }}>{option.localidade}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            )}
                                        />

                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.instalacaoId && formik.touched.instalacaoId &&
                                    <ErrorValidationMessage message={formik.errors.instalacaoId} />
                                }
                            </div>

                            <div className="p-field p-col-4 p-sm-2">
                                <label
                                    htmlFor="peso"
                                    className={classNames({ 'p-error': isFormFieldValid('peso') })}
                                >
                                    Peso
                                </label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="peso"
                                            name="peso"
                                            type="number"
                                            className={classNames({ 'p-invalid': isFormFieldValid('peso') })}
                                            value={formik.values.peso}
                                            onChange={formik.handleChange}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.peso && formik.touched.peso &&
                                    <ErrorValidationMessage message={formik.errors.peso} />
                                }
                            </div>

                            <div className="p-field p-col-4 p-sm-2">
                                <label
                                    htmlFor="ativo"
                                    className={classNames({ 'p-error': isFormFieldValid('ativo') })}
                                >
                                    ativo
                                </label>
                                {
                                    !isLoading ?

                                        <input type="checkbox" className="table-data" checked={formik.values.ativo} onChange={handleCheckboxChange} />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.ativo && formik.touched.ativo &&
                                    <ErrorValidationMessage message={formik.errors.ativo} />
                                }
                            </div>


                            <div className="p-d-flex p-jc-end p-mr-1">

                                <Button
                                    icon="pi pi-plus"
                                    type="button"
                                    iconPos="right"
                                    onClick={formik.handleSubmit}
                                    loading={isSubmiting}
                                />
                            </div>


                        </div>

                    </form>
                </div>
            </div>


        </Fieldset>

    );
}

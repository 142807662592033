import React, { useRef, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import '../layout/sass/_pickListSugestoes.scss';
import '../layout/sass/_responsiveTable.scss';
import api from '../api/api';


const TabelaInstalacaoEmpenho = React.memo(({ isEditing, listInstalacaoEmpenho, setListInstalacaoEmpenho }) => {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        const fetchInstalacaoEmpenho = async () => {
            try {
                await getInstalacaoEmpenho();
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        };
        fetchInstalacaoEmpenho();
    }, []);

    const getInstalacaoEmpenho = async () => {
        setIsLoading(true);
        try {
            if (isEditing) {
                const response = await api.get(`instalacaoEmpenho/instalacao/${id}`);
                setListInstalacaoEmpenho(response.data);
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter instalacões', detail: error.toString() });
        }
        setIsLoading(false);
    };

    const ativoBodyTemplate = (rowData, { rowIndex }) => {
        handleEditClick(rowData);

        const handleCheckboxChange = (event) => {
            const updatedInstalacaoEmpenho = [...listInstalacaoEmpenho];
            updatedInstalacaoEmpenho[rowIndex].ativo = event.target.checked;
            setListInstalacaoEmpenho(updatedInstalacaoEmpenho);
        };

        return (
            <React.Fragment>
                <p style={{ textAlign: 'center' }}>
                    <span className="p-column-title">Ativo</span>
                    <input
                        type="checkbox"
                        className="table-data"
                        checked={rowData.ativo}
                        onChange={handleCheckboxChange}
                    />
                </p>
            </React.Fragment>
        );
    };

    const pesoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Peso</span>
                    <span className="table-data">{rowData.peso}</span>
                </p>
            </React.Fragment>
        );
    };

    const empenhoBodyTemplate = (rowData) => {

        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Empenho</span>
                    <span className="table-data">{rowData.numeroEmpenho} - {rowData.empenhoAnoRef}</span>
                </p>
            </React.Fragment>
        );
    };

    const saldoEmpenhoBodyTemplate = (rowData) => {

        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Saldo Empenho</span>
                    <span className="table-data">R$ {rowData.saldoEmpenho != undefined ? rowData.saldoEmpenho.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span>
                </p>
            </React.Fragment>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-rounded p-mr-2 p-button-danger"
                    onClick={() => onRowDeleteComplete(rowData)}
                />
            </div>
        );
    };

    const handleEditClick = async (data) => {

        const response = await api.put(`instalacaoEmpenho`, data);

        if (response.status == 200) {
            toast.current.show({
                life: 5000,
                severity: 'success',
                summary: 'Atualização concluída!',
                detail: `Item ${data.descricao} atualizado com sucesso`
            });
        }
    };

    const onRowDeleteComplete = async (data) => {

        const response = await api.delete(`instalacaoEmpenho/${data.id}`);

        let { index } = data; // Supondo que você tem o índice do item a ser removido
        let updatedInstalacaoEmpenho = [...listInstalacaoEmpenho];
        updatedInstalacaoEmpenho.splice(index, 1); // Remove 1 item na posição 'index'
        setListInstalacaoEmpenho(updatedInstalacaoEmpenho);

        if (response.status == 200) {
            toast.current.show({
                life: 5000,
                severity: 'success',
                summary: 'Remoção concluída!',
                detail: `Item ${data.descricao} removido com sucesso`
            });
        }
    };

    const onRowEditComplete = (e) => {
        let updatedInstalacaoEmpenho = [...listInstalacaoEmpenho];
        let { newData, index } = e;
        updatedInstalacaoEmpenho[index] = newData;
        setListInstalacaoEmpenho(updatedInstalacaoEmpenho);
    };

    const editarPeso = (options) => {
        return (
            <InputNumber
                value={options.rowData.peso}
                onValueChange={(e) => {
                    options.rowData.peso = e.value;
                    setListInstalacaoEmpenho([...listInstalacaoEmpenho]);
                }}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                currency="BRL"
                locale="pt-BR"
            />
        );
    };

    return (
        <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="card p-col-12 datatable-responsive-demo">
                <div className="p-fluid">
                    <DataTable
                        editMode="row"
                        onRowEditComplete={onRowEditComplete}
                        responsiveLayout="scroll"
                        value={listInstalacaoEmpenho}
                        className="p-datatable-responsive-demo"
                        emptyMessage="Nenhuma instalação encontrada"
                        dataKey="id"
                        paginator
                        rows={20}
                        currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} instalacões"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[20, 30]}
                        sortField="nome"
                        sortOrder={1}
                        removableSort
                    >
                        <Column field="empenho" header="Empenho" sortable body={empenhoBodyTemplate} style={{ width: '30%' }} />

                        <Column field="saldoEmpenho" header="Saldo Empenho" sortable body={saldoEmpenhoBodyTemplate} style={{ width: '30%' }} />

                        <Column
                            field="peso"
                            header="Peso"
                            sortable
                            body={pesoBodyTemplate}
                            editor={editarPeso}
                            style={{ width: '30%' }}
                        />

                        <Column rowEditor headerStyle={{ width: '20%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }} />

                        <Column
                            field="ativo"
                            header="Ativo"
                            sortable
                            body={ativoBodyTemplate}
                            style={{ width: '30%' }}
                        />
                        <Column header="Ações" body={actionBodyTemplate} style={{ width: '30%' }} />
                    </DataTable>
                </div>
            </div>
        </div>
    );
});

export default TabelaInstalacaoEmpenho;  // Export the memoized component

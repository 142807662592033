import React, { useRef, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import api from "../api/api";

export const TabelaEmpenhoHistorico = ({ isEditing, empenhoId }) => {
    const toast = useRef(null);
    const [faturas, setFaturas] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showListaEmpenhoHistorico, setShowListaEmpenhoHistorico] = useState(false);


    useEffect(() => {
        if (isEditing && empenhoId) {
            fetchEmpenhoHistorico(empenhoId);
        }
    }, [isEditing, empenhoId]);

    const fetchEmpenhoHistorico = async (empenhoId) => {
        setIsLoading(true);
        try {
            const instalacoesResponse = await api.get(`instalacaoEmpenho/empenho/${empenhoId}`);
            const instalacoes = instalacoesResponse.data;            

            if (!instalacoes.length) {
                setFaturas([]);
                return;
            }

            const todasFaturas = await api.get(`faturas/empenhoId/${empenhoId}`)            
            
            setFaturas(todasFaturas.data);

        } catch (error) {
            
            toast.current.show({ severity: "error", summary: "Erro", detail: error.toString() });
        }
        setIsLoading(false);
    };

    const numeroBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">N° Fatura</span>
                    <span className="table-data">{rowData.faturaNumero}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataEmissaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Data Emissão</span>
                    <span className="table-data">{new Date(rowData.faturaDataEmissao).toLocaleDateString()}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataVencimentoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Data Vencimento</span>
                    <span className="table-data">{new Date(rowData.faturaDataVencimento).toLocaleDateString()}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataLiquidacaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Data Liquidação</span>
                    <span className="table-data">{new Date(rowData.faturaDataLiquidacao).toLocaleDateString()}</span>
                </p>
            </React.Fragment>
        );
    }

    const valorTotalBodyTemplate = (rowData) => {        

        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Valor Liquidado</span>
                    {rowData.valorLiquidado &&
                        <span className="table-data">{rowData.valorLiquidado.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        })}</span>}

                </p>
            </React.Fragment>
        );
    }

    const instalacaoBodyTemplate = (rowData) => {        

        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Valor Liquidado</span>
                    {rowData.valorLiquidado &&
                        <span className="table-data">{rowData.instalacaoOrEmpenho}</span>}

                </p>
            </React.Fragment>
        );
    }

    return (
        <div className="card p-grid p-col-12">
            <Toast ref={toast} position="bottom-right" />

            <div className="card p-col-12 datatable-responsive-demo">
                <DataTable
                    responsiveLayout="scroll"
                    value={faturas}
                    className="p-datatable-responsive-demo"
                    emptyMessage="Nenhuma fatura encontrada"
                    dataKey="id"
                    paginator
                    rows={20}
                    currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} faturas"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[20, 30]}
                    sortField="numero"
                    sortOrder={1}
                    removableSort
                    loading={isLoading}
                >
                    <Column
                        field="numero"
                        header="N° Fatura"
                        sortable
                        body={numeroBodyTemplate}
                    />

                    <Column
                        field="dataEmissao"
                        header="Data Emissão"
                        body={dataEmissaoBodyTemplate}
                    />

                    <Column
                        field="dataVencimento"
                        header="Data Vencimento"
                        body={dataVencimentoBodyTemplate}
                    />

                    <Column
                        field="dataLiquidacao"
                        header="Data Liquidação"
                        body={dataLiquidacaoBodyTemplate}
                    />


                    <Column
                        field="instalacaoOrEmpenho"
                        header="Instalação"
                        body={instalacaoBodyTemplate}

                    />

                    <Column
                        field="valorTotal"
                        header="Valor Total"
                        body={valorTotalBodyTemplate}

                    />
                </DataTable>
            </div>
        </div>
    );
};
import React, { useState, useCallback, useContext, useEffect } from 'react';

import { Dropdown } from 'primereact/dropdown';

import { AuthContext } from './contexts/AuthContext';

import { PermissaoUsuario } from './components/PermissaoUsuario';

import { useHistory } from "react-router-dom";

import api from './api/api';
import './layout/layout.scss';

export const AppProfile = () => {

    const { user, signOut } = useContext(AuthContext);
    const history = useHistory();
    const [fornecedores, setFornecedores] = useState([]);   
    const [listAnoRef, setListAnoRef] = useState([]);
    const [fornecedor, setFornecedor] = useState(null);
    const [anoRef, setAnoRef] = useState(null);

    // const [expanded, setExpanded] = useState(false);
    const [showDialogPermissao, setShowDialogPermissao] = useState(false);

    const onClick = () => {
        if (user.perfilUser.includes('admin') && user.perfilUser.includes('user')) {
            setShowDialogPermissao(true);
        }
    }

    const handleGetSelectedFornecedor = () => {
        const fornecedorStorage = JSON.parse(localStorage.getItem('fornecedor'));

        if (fornecedorStorage) {
            setFornecedor(fornecedorStorage);
        } else {
            localStorage.setItem('fornecedor', 0)
            localStorage.setItem('fornecedorNome', JSON.stringify("TODOS"))
            setFornecedor(0)
        }
    }

    const handleGetSelectedAno = () => {
        const anoStorage = JSON.parse(localStorage.getItem('anoRef'));

        if (anoStorage) {
            setAnoRef(anoStorage);
        } else {
            localStorage.setItem('anoRef', 0)
            setAnoRef(0)
        }
    }

    const handleGetFornecedores = useCallback(async () => {

        await api.get("fornecedor/fornecedorAll").then((response) => {

            const resp = response.data.map(item => {

                return {
                    id: item.id,
                    label: `${item.codigo} - ${item.nome}`,
                    nome: item.nome,
                    value: item.id,
                }
            });

            setFornecedores([{
                id: 0,
                value: 0,
                nome: 'TODOS',
                label: 'TODOS'
            }, ...resp]);
            handleGetSelectedFornecedor();
        });

    }, []);

    const handleGetAnoRef = useCallback(async () => {

        await api.get("/empenho/anoRefList").then((response) => {

            const resp = response.data.map(item => {

                return {
                    label: JSON.stringify(item),
                    value: item,
                }
            });

            setListAnoRef([{
                label: 'TODOS',
                value: 0,
            }, ...resp]);
            handleGetSelectedAno();
        });

    }, []);


    useEffect(async () => {
            await handleGetFornecedores();
            await handleGetAnoRef();
    }, []);

    const refreshData = () => {
        const currentPath = history.location.pathname;
        history.go(currentPath);
    }

    const handleFornecedoresSelection = (event) => {
        const selection = event.value;
        const ff = fornecedores.find((f) => f.id === event.value);
        localStorage.setItem('fornecedorNome', JSON.stringify(ff.nome));
        localStorage.setItem('fornecedor', JSON.stringify(selection));
        setFornecedor(selection);
        refreshData();
    }

    const handleAnoRefSelection = (event) => {
        const selection = event.value;
        localStorage.setItem('anoRef', JSON.stringify(selection));
        setAnoRef(selection);
        refreshData();
    }

    const valueTemplate = (option, props) => {
        if (!option) return props.placeholder;
        return option.label.length > 10 ? option.label.substring(0, 10) + "..." : option.label;
    };

    return (
        <div className="layout-profile">
            <PermissaoUsuario
                visible={showDialogPermissao}
                setVisible={setShowDialogPermissao}
                permissao="Administrador"
            />
            <div>
                <button className="p-link layout-profile-link" onClick={onClick}>
                    <span className="username">{user !== null ? user.nome.toUpperCase() : ""}</span>
                    {/* <i className="pi pi-fw pi-cog" /> */}
                </button>
            </div>
            <div className="p-my-2 p-px-2" style={{ display: 'flex', alignItems: 'center' }}>
                <label className="p-ml-1 label-perfil-user" style={{ marginRight: '8px' }}>Fornecedor:</label>
                <Dropdown
                    value={fornecedor}
                    options={fornecedores}
                    optionLabel="label"
                    onChange={handleFornecedoresSelection}
                    valueTemplate={valueTemplate}
                />
            </div>
            <div className="p-my-2 p-px-2" style={{ display: 'flex', alignItems: 'center' }}>
                <label className="p-ml-1 label-perfil-user" style={{ marginRight: '8px' }}>Ano Referência:</label>
                <Dropdown
                    value={anoRef}
                    options={listAnoRef}
                    optionLabel="label"
                    onChange={handleAnoRefSelection}
                    className="perfil-user-dropdown"
                />
            </div>

            <button type="button" onClick={signOut} className="p-link p-mt-3">
                <i className="pi pi-fw pi-sign-out p-mr-2" /><span>Sair</span>
            </button>
        </div>
    );

}

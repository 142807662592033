import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ErrorValidationMessage } from './ErrorValidationMessage';
import api from '../api/api';
import '../layout/sass/_form.scss';
import { InputNumber } from 'primereact/inputnumber';

export const FormFaturaItens = ({ isEditing, isLoading, setFaturaItem, faturaId }) => {
    const toast = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [listInstalacoes, setListInstalacoes] = useState([]);
    const [listItens, setListItens] = useState([]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const schema = Yup.object().shape({
        instalacaoId: Yup.number().required("A instalação é obrigatória"),
        valorTotal: Yup.number().required("O valor total é obrigatório"),
        valorRetencao: Yup.number().required("O valor de retenção é obrigatório"),
    });

    const formik = useFormik({
        initialValues: {
            faturaId,
            instalacaoId: "",
            quantidade: 1,
            valorTotal: 0,
            valorRetencao: 0,
        },
        validationSchema: schema,
        enableReinitialize: false,
        onSubmit: async (data) => {
            setIsSubmitting(true);

            try {
                const response = await api.post("faturaItem", data);
                setFaturaItem(prevFaturaItem => [...prevFaturaItem, response.data]);
                toast.current.show({ life: 2000, severity: 'success', summary: 'Item incluído!', detail: 'Item adicionado com sucesso' });
                formik.resetForm();
            } catch (error) {
                toast.current.show({ life: 3000, severity: 'error', summary: 'Erro', detail: 'Falha ao adicionar o item' });
            } finally {
                setIsSubmitting(false);
            }
        }
    });

    const handleGetInstalacoes = useCallback(async () => {
        try {
            const response = await api.get("instalacao/instalacaoAll");
            const itens = response.data.map(item => ({
                id: item.id,
                numeroInstalacao: item.numeroInstalacao,
                endereco: item.endereco,
                localidade: item.localidade,
            }));
            setListInstalacoes(itens);
        } catch (error) {
            console.error("Erro ao buscar instalações", error);
        }
    }, []);

    useEffect(() => {
        handleGetInstalacoes();
    }, [handleGetInstalacoes]);

    return (
        <Fieldset legend="Itens" toggleable>
            <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
                <Toast ref={toast} position="bottom-right" />
                <div className="p-col-12">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-formgrid p-grid p-fluid p-mx-0">
                            <div className="p-field p-col-12 p-sm-6">
                                <label htmlFor="instalacaoId" className={classNames({ 'p-error': isFormFieldValid('instalacaoId') })}>
                                    Instalações
                                </label>
                                {!isLoading ?
                                    <Dropdown
                                        id="instalacaoId"
                                        name="instalacaoId"
                                        options={listInstalacoes}
                                        filter
                                        emptyMessage="Nenhuma instalação encontrada"
                                        optionLabel={(option) => `${option.numeroInstalacao} - ${option.endereco} - ${option.localidade}`}
                                        optionValue="id"
                                        placeholder="Selecione uma instalação"
                                        className={classNames({ 'p-invalid': isFormFieldValid('instalacaoId') })}
                                        value={formik.values.instalacaoId}
                                        onChange={(option) => formik.setFieldValue("instalacaoId", option.value)}
                                    />
                                    : <Skeleton height="35px" />}
                                {formik.errors.instalacaoId && formik.touched.instalacaoId && <ErrorValidationMessage message={formik.errors.instalacaoId} />}
                            </div>

                            <div className="p-field p-col-12 p-sm-1">
                                <label htmlFor="quantidade" className={classNames({ 'p-error': isFormFieldValid('quantidade') })}>
                                    Quantidade
                                </label>
                                {!isLoading ?
                                    <InputText
                                        id="quantidade"
                                        name="quantidade"
                                        type="number"
                                        className={classNames({ 'p-invalid': isFormFieldValid('quantidade') })}
                                        value={formik.values.quantidade}
                                        onChange={formik.handleChange}
                                    />
                                    : <Skeleton height="35px" />}
                                {formik.errors.quantidade && formik.touched.quantidade && <ErrorValidationMessage message={formik.errors.quantidade} />}
                            </div>

                            <div className="p-field p-col-12 p-sm-2">
                                <label htmlFor="valorTotal" className={classNames({ 'p-error': isFormFieldValid('valorTotal') })}>
                                    Valor total
                                </label>
                                {!isLoading ?
                                    <InputNumber
                                        id="valorTotal"
                                        name="valorTotal"
                                        mode="currency" minFractionDigits={2}
                                        currency="BRL"
                                        className={classNames({ 'p-invalid': isFormFieldValid('valorTotal') })}
                                        locale="pt-BR"
                                        value={formik.values.valorTotal}
                                        onChange={(e) => formik.setFieldValue("valorTotal", e.value)}
                                    />
                                    : <Skeleton height="35px" />}
                                {formik.errors.valorTotal && formik.touched.valorTotal && <ErrorValidationMessage message={formik.errors.valorTotal} />}
                            </div>

                            <div className="p-field p-col-12 p-sm-2">
                                <label htmlFor="valorRetencao" className={classNames({ 'p-error': isFormFieldValid('valorRetencao') })}>
                                    Valor Retenção
                                </label>
                                {!isLoading ?
                                    <InputNumber
                                        id="valorRetencao"
                                        name="valorRetencao"
                                        mode="currency" minFractionDigits={2}
                                        currency="BRL"
                                        className={classNames({ 'p-invalid': isFormFieldValid('valorRetencao') })}
                                        locale="pt-BR"
                                        value={formik.values.valorRetencao}
                                        onChange={(e) => formik.setFieldValue("valorRetencao", e.value)}
                                    />
                                    : <Skeleton height="35px" />}
                                {formik.errors.valorRetencao && formik.touched.valorRetencao && <ErrorValidationMessage message={formik.errors.valorRetencao} />}
                            </div>

                            <div className="p-d-flex p-jc-end p-mr-1">
                                <Button
                                    icon="pi pi-plus"
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmitting}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fieldset>
    );
};

import React, { useRef, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { DialogInstalacaoHistorico } from './DialogInstalacaoHistorico';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';
import { InputNumber } from 'primereact/inputnumber';

export const TabelaInstalacaoHistorico = ({ isEditing, instalacaoHistorico, setInstalacaoHistorico }) => {
    const toast = useRef(null);
    const [instalacoesHistoricos, setInstalacoesHistoricos] = useState([]);
    const [instalacoes, setInstalacoes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showListaInstalacaoHistorico, setShowListaInstalacaoHistorico] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const confirmationToast = useRef(null)
    const [dt, setDt] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchInstalacaoHistorico = async () => {
            try {
                await getInstalacaoHistorico();
            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchInstalacaoHistorico();

    }, [])

    useEffect(() => {
        const fetchInstalacoes = async () => {
            try {
                await getInstalacoes();
            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchInstalacoes();

    }, [])

    const getInstalacaoHistorico = async () => {
        setIsLoading(true)
        setInstalacoesHistoricos([])

        try {

            if (isEditing) {
                const response = await api.get(`faturas/instalacaoId/${id}`);

                response.data.map(async historico => {
                    setInstalacoesHistoricos(instalacoesHistoricos => [...instalacoesHistoricos, historico])
                })
            }

        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter instalacões', detail: error.toString() });
        }

        setIsLoading(false)
    }


    const getInstalacoes = async () => {
        setIsLoading(true)
        setInstalacoes([])

        try {

            if (isEditing) {
                const response = await api.get(`instalacao/${id}`);

                setInstalacoes({
                    'id': response.data.id,
                    'numeroInstalacao': response.data.numeroInstalacao,
                    'endereco': response.data.endereco,
                    'responsavel': response.data.responsavel,
                    'dataVigencia': response.data.dataVigencia,
                })


            }

        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter instalacões', detail: error.toString() });
        }

        setIsLoading(false)
    }



    const numeroBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textAlign: 'center' }}>
                    <span className="p-column-title">N° Fatura</span>
                    <span className="table-data">{rowData.faturaNumero}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataEmissaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textAlign: 'center' }}>
                    <span className="p-column-title">Data Emissão</span>
                    <span className="table-data">{new Date(rowData.faturaDataEmissao).toLocaleDateString()}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataVencimentoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textAlign: 'center' }}>
                    <span className="p-column-title">Data Vencimento</span>
                    <span className="table-data">{new Date(rowData.faturaDataVencimento).toLocaleDateString()}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataLiquidacaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textAlign: 'center' }}>
                    <span className="p-column-title">Data Vencimento</span>
                    <span className="table-data">{new Date(rowData.faturaDataLiquidacao).toLocaleDateString()}</span>
                </p>
            </React.Fragment>
        );
    }


    const descricaoBodyTemplate = (rowData) => {        

        return (
            <React.Fragment>
                <p style={{ textAlign: 'center' }}>
                    <span className="p-column-title">Empenho - Ano</span>
                    <span className="table-data">{rowData.instalacaoOrEmpenho}</span>
                </p>
            </React.Fragment>
        );
    }



    const valorLiquidadoBodyTemplate = (rowData) => {                       

        return (
            <React.Fragment>
                <p style={{ textAlign: 'center' }}>
                    <span className="p-column-title">Valor Liquidado</span>
                    {rowData.valorLiquidado &&
                        <span className="table-data">{rowData.valorLiquidado.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        })}</span>}

                </p>
            </React.Fragment>
        );
    }



    useEffect(() => {

    }, [instalacaoHistorico, instalacoes]);




    const handlePrintClick = () => {
        setShowListaInstalacaoHistorico(true);
    }



    return (

        <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

            <DialogInstalacaoHistorico
                visible={showListaInstalacaoHistorico}
                setVisible={setShowListaInstalacaoHistorico}
                instalacaoHistorico={instalacaoHistorico}
                instalacoes={instalacoes}
            />

            <div className="p-my-0 p-dir-rev p-col">
                <div className="p-my-0">
                    <Button
                        textalign="center"
                        icon="pi pi-print"
                        className="p-button-secondary"
                        style={{ width: '35px' }}
                        onClick={handlePrintClick}
                        tooltip="Gerar relatório"
                        tooltipOptions={{ position: 'top' }}
                    />
                </div>
            </div>

            <Toast ref={toast} position="bottom-right" />

            <Toast ref={confirmationToast} position="bottom-center" />

            <div className="card p-col-12 datatable-responsive-demo">

                <div className="p-fluid">

                    <DataTable
                        editMode="row"
                        // onRowEditComplete={onRowEditComplete1}
                        responsiveLayout="scroll"
                        value={instalacaoHistorico}
                        className="p-datatable-responsive-demo"
                        emptyMessage="Nenhuma instalação encontrada"
                        dataKey="id"
                        paginator
                        rows={20}
                        currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} instalacões"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[20, 30]}
                        sortField="nome"
                        sortOrder={1}
                        removableSort
                        style={{ borderStyle: "#000" }}
                    >

                        <Column
                            field="numero"
                            header="N° Fatura"                            
                            body={numeroBodyTemplate}

                        />

                        <Column
                            field="dataEmissao"
                            header="Data Emissão"                            
                            body={dataEmissaoBodyTemplate}

                        />

                        <Column
                            field="dataVencimento"
                            header="Data Vencimento"                            
                            body={dataVencimentoBodyTemplate}

                        />

                        <Column
                            field="dataLiquidacao"
                            header="Data Liquidação"                            
                            body={dataLiquidacaoBodyTemplate}

                        />

                        <Column
                            field="descricao"
                            header="Empenho - Ano"                            
                            body={descricaoBodyTemplate}                            
                        />


                        <Column
                            field="valorTotal"
                            header="Valor Liquidado"
                            body={valorLiquidadoBodyTemplate}

                        />

                    </DataTable>

                </div>
            </div>
        </div>

    );
}

import React from 'react';

import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';
import { TabelaFaturas } from '../../components/TabelaFaturas';

export default function LiquidadasPage() {

    return (
        <>
            <MenuMain >
                <TabelaFaturas liquidadas={true} />
            </MenuMain>
            <AppFooter />
        </>
    );
}

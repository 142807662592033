import React, { useRef, useState, useEffect, useCallback, useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Fieldset } from 'primereact/fieldset';
import { useParams } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';

import api from '../api/api';

import '../layout/sass/_form.scss';
import { AuthContext } from '../contexts/AuthContext';

export const FormInstalacaoEmpenho = ({ isEditing, isLoading, setInstalacaoEmpenho }) => {

    const { id } = useParams();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [listEmpenhos, setListEmpenhos] = useState(false);
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const { anoRef, fornecedorNome } = useContext(AuthContext);

    const schema = Yup.object().shape({
        peso: Yup
            .number()
            .required("O peso é obrigatório"),
        empenhoId: Yup.object({
            id: Yup.number().required('Empenho ID é obrigatório'),
            // numeroEmpenho: Yup.string().required('Número do empenho é obrigatório'),
            // Adicione mais validações para outras propriedades do objeto
          }).required('Empenho é obrigatório')

    })

    useEffect(() => {

        if (isEditing) {
            api.get(`instalacao/${id}`).then((response) => {
                formik.setFieldValue("instalacaoId", response.data.instalacaoId);
            });
        }

    }, []);

    const formik = useFormik({

        initialValues: {
            "peso": 1,
            "empenhoId": null,
            "empenhoNumero": null,
            "ativo": false,
            "instalacaoId": null
        },

        validationSchema: schema,

        enableReinitialize: false,

        onSubmit: (data) => {

            if (isSubmiting) return;            

            data.instalacaoId = id;  
            
            data.empenhoId = data.empenhoId.id;

            data.saldo = data.empenhoId.saldo;

            setIsSubmiting(true)            

            setInstalacaoEmpenho(instalacaoEmpenho => [...instalacaoEmpenho, data]);

            setIsSubmiting(false)


        }
    })

    const handleChangeEmpenho = (e) => {

        formik.setFieldValue("empenhoNumero", e.value.numeroEmpenho);

    }

    const handleGetEmpenhos = useCallback(async () => {

        if (fornecedorNome === "TODOS" || anoRef === 0) {
            await api.get("empenho/empenhoAll").then((response) => {

                const empenhos = response.data.map(item => {
                    return {
                        id: item.id,
                        numeroEmpenho: item.numeroEmpenho,
                        ficha: item.ficha,
                        fonte: item.fonte,
                        valorEmpenho: item.valorEmpenho,
                        secretariaId: item.secretariaId,
                        anoRef: item.anoRef
                    }
                });

                setListEmpenhos(empenhos);
            });
        } else {
            const pesquisa = {
                "fornecedorNome": fornecedorNome,
                "anoRef": anoRef,
            }
            
            const respEmpenhos = await api.post(`empenho/searchEmpenho/ASC/id?`, pesquisa);
            respEmpenhos.data.content.forEach(empenho => {
                setListEmpenhos(empenhos => [...empenhos, {
                    id: empenho.id,
                    numeroEmpenho: empenho.numeroEmpenho,
                    ficha: empenho.ficha,
                    fonte: empenho.fonte,
                    valorEmpenho: empenho.valorEmpenho,
                    secretariaId: empenho.secretariaId,
                    anoRef: empenho.anoRef
                }])
            });
        }

    }, [])

    useEffect(async () => {
        await handleGetEmpenhos();

    }, [handleGetEmpenhos]);

    const handleCheckboxChange = (event) => {
        formik.setFieldValue('ativo', event.target.checked);
    };

    return (

        <Fieldset legend="Empenhos" toggleable  >
            <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
                <Toast ref={toast} position="bottom-right" />
                <div className="p-col-12 p-sm-12">

                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-formgrid p-grid p-fluid p-mx-0" >

                            <div className="p-field p-col-4 p-sm-4">
                                <label
                                    htmlFor="empenhoId"
                                    className={classNames({ 'p-error': isFormFieldValid('empenhoId') })}
                                >
                                    Empenhos.
                                </label>
                                {

                                    !isLoading ?
                                        <Dropdown
                                            id="empenhoId"
                                            name="empenhoId"
                                            options={listEmpenhos}
                                            filter={true}
                                            emptyMessage={"Nenhum empenho encontrado"}
                                            optionLabel="numeroEmpenho" // Aqui você define qual propriedade do objeto será mostrada no dropdown
                                            placeholder="Selecione um empenho"
                                            className={classNames({ 'p-invalid': isFormFieldValid('empenhoId') })}
                                            value={formik.values.empenhoId} // Aqui você está passando o objeto inteiro para o value
                                            onChange={(e) => {
                                                // Atualiza o Formik com o objeto inteiro
                                                formik.setFieldValue('empenhoId', e.value);
                                                handleChangeEmpenho(e); // Se necessário para outras ações
                                            }}
                                            emptyFilterMessage={"Nenhum empenho encontrado"}
                                            itemTemplate={(option) => (
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr key={option.id}>
                                                            <td style={{ width: '10%', padding: '3px' }}>{option.numeroEmpenho}</td>
                                                            <td style={{ width: '10%', padding: '3px' }}>{option.anoRef}</td>
                                                            <td style={{ width: '20%', padding: '3px' }}>{option.ficha}</td>
                                                            <td style={{ width: '15%', padding: '3px' }}>{option.fonte}</td>
                                                            <td style={{ width: '25%', padding: '3px' }}>{option.valorEmpenho}</td>
                                                            <td style={{ width: '30%', padding: '3px' }}>{option.secretariaId}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            )}
                                        />


                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.empenhoId && formik.touched.empenhoId &&
                                    <ErrorValidationMessage message={formik.errors.empenhoId} />
                                }
                            </div>

                            <div className="p-field p-col-4 p-sm-2">
                                <label
                                    htmlFor="peso"
                                    className={classNames({ 'p-error': isFormFieldValid('peso') })}
                                >
                                    Peso
                                </label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="peso"
                                            name="peso"
                                            type="number"
                                            className={classNames({ 'p-invalid': isFormFieldValid('peso') })}
                                            value={formik.values.peso}
                                            onChange={formik.handleChange}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.peso && formik.touched.peso &&
                                    <ErrorValidationMessage message={formik.errors.peso} />
                                }
                            </div>

                            <div className="p-field p-col-4 p-sm-2">
                                <label
                                    htmlFor="ativo"
                                    className={classNames({ 'p-error': isFormFieldValid('ativo') })}
                                >
                                    ativo
                                </label>
                                {
                                    !isLoading ?

                                        <input type="checkbox" className="table-data" checked={formik.values.ativo} onChange={handleCheckboxChange} />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.ativo && formik.touched.ativo &&
                                    <ErrorValidationMessage message={formik.errors.ativo} />
                                }
                            </div>


                            <div className="p-d-flex p-jc-end p-mr-1">

                                <Button
                                    icon="pi pi-plus"
                                    type="button"
                                    iconPos="right"
                                    onClick={formik.handleSubmit}
                                    loading={isSubmiting}
                                />
                            </div>


                        </div>

                    </form>
                </div>
            </div>


        </Fieldset>

    );
}

import React, { useRef, useEffect, useState, useCallback, useContext } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import '../layout/sass/_pickListSugestoes.scss'

import '../layout/sass/_responsiveTable.scss'
import { confirmDialog } from 'primereact/confirmdialog';
import api from '../api/api';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { FormUpload } from './FormUpload';

export const TabelaEmpenho = ({ filter }) => {
    const history = useHistory();
    const toast = useRef(null);
    const { anoRef, fornecedorNome, user } = useContext(AuthContext);
    const [empenho, setEmpenho] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const confirmationToast = useRef(null)
    const [sortable, setSortable] = useState('ASC');
    const [first, setFirst] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [sortableCampo, setSortableCampo] = useState('id');
    const [visible, setVisible] = useState(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 20,
        sortField: null,
        sortOrder: null,
        filters: {
            'id': { value: "", matchMode: 'contains' },
            'secretariaNome': { value: "", matchMode: 'contains' },
            'ficha': { value: "", matchMode: 'contains' },
            'fonte': { value: "", matchMode: 'contains' },
            'numeroEmpenho': { value: "", matchMode: 'contains' },
            'fornecedorNome': { value: fornecedorNome === "TODOS" ? "" : fornecedorNome, matchMode: 'contains' },
            'anoRef': { value: anoRef === 0 ? "" : anoRef, matchMode: 'contains' }
        }
    });

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">{filter ? "Empenhos com mais de 75% liquidados" : "Empenhos"}</h2>
            </div>
            {!filter &&
                <div className="p-my-0 p-grid p-dir-rev p-col">
                    <div className="p-m-2">
                        <Button
                            label="Importar"
                            className="p-mb-2 p-button-primary"
                            icon="pi pi-plus"
                            iconPos="left"
                            type="button"

                            onClick={() => history.push('/empenhocreate')}

                        />
                    </div>
                </div>
            }
        </div>

    );


    const getEmpenho = useCallback(async (event) => {
        setIsLoading(true)
        setEmpenho([])

        if (event !== undefined) {
            setTimeout(() => {
                const startIndex = event.first;

                setFirst(startIndex);
            }, 250);
        }

        try {
            if (lazyParams.filters === undefined) {

                if (event === undefined) {
                    const response = await api.get(`empenho?sort=${sortableCampo},${sortable}`);

                    response.data.content.forEach(empenho => {
                        setEmpenho(empenhos => [...empenhos, empenho])
                    })
                } else {
                    const response = await api.get(`empenho?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(empenho => {
                        setEmpenho(empenhos => [...empenhos, empenho])
                    })

                }

                const respElements = await api.get(`empenho?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);

            } else {
                const pesquisa = {
                    "id": lazyParams.filters['id'] !== undefined ? lazyParams.filters['id'].value : "",
                    "secretariaNome": lazyParams.filters['secretariaNome'] !== undefined ? lazyParams.filters['secretariaNome'].value : "",
                    "ficha": lazyParams.filters['ficha'] !== undefined ? lazyParams.filters['ficha'].value : "",
                    "fonte": lazyParams.filters['fonte'] !== undefined ? lazyParams.filters['fonte'].value : "",
                    "numeroEmpenho": lazyParams.filters['numeroEmpenho'] !== undefined ? lazyParams.filters['numeroEmpenho'].value : "",
                    "fornecedorNome": lazyParams.filters['fornecedorNome'] !== undefined ? lazyParams.filters['fornecedorNome'].value : "",
                    "anoRef": lazyParams.filters['anoRef'] !== undefined ? lazyParams.filters['anoRef'].value : "",
                    "filter": filter
                }

                if (event === undefined) {
                    const respElements = await api.post(`empenho/searchEmpenho/${sortable}/${sortableCampo}?`, pesquisa);
                    respElements.data.content.forEach(empenho => {
                        setEmpenho(empenhos => [...empenhos, empenho])
                    })
                    setTotalRecords(respElements.data.totalElements);
                } else {
                    const respElements = await api.post(`empenho/searchEmpenho/${sortable}/${sortableCampo}?page=${event.page}`, pesquisa);
                    respElements.data.content.forEach(empenho => {
                        setEmpenho(empenhos => [...empenhos, empenho])
                    })
                    setTotalRecords(respElements.data.totalElements);
                }
            }

        } catch (error) {
            console.error('Erro inesperado:', error);
            if (lazyParams !== undefined) {
                // await getRequest(`empenho?sort=secretariaNome,${sortable}`);
            } else {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter empenho', detail: error.toString() });
            }
        }

        setIsLoading(false);
    }, [lazyParams, sortable, sortableCampo]);


    useEffect(() => {
        const fetchdotacaoOrcamentaria = async () => {
            try {
                await getEmpenho();

            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchdotacaoOrcamentaria();

    }, [getEmpenho])

    const idBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const secretariaBodyTemplate = (rowData) => {

        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Secretaria</span>
                    <span className="table-data">{rowData.secretaria != null ? rowData.secretaria.nomund : ""}</span>
                </p>
            </React.Fragment>
        );
    }

    const fichaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Ficha</span>
                    <span className="table-data">{rowData.ficha}</span>
                </p>
            </React.Fragment>
        );
    }

    const fonteBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Fonte</span>
                    <span className="table-data">{rowData.fonte}</span>
                </p>
            </React.Fragment>
        );
    }

    const numeroEmpenhoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Empenho</span>
                    <span className="table-data">{rowData.numeroEmpenho}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataEmissaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Data Emissão</span>
                    <span className="table-data">{new Date(rowData.dataEmissao).toLocaleDateString()}</span>
                </p>
            </React.Fragment>
        );
    }

    const dataVigenciaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Data Vigência</span>
                    <span className="table-data">{new Date(rowData.dataVigencia).toLocaleDateString()}</span>
                </p>
            </React.Fragment>
        );
    }

    const fornecedorBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Fornecedor</span>
                    <span className="table-data">{rowData.fornecedor.nome}</span>
                </p>
            </React.Fragment>
        );
    }

    const valorTotalBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Valor Empenho</span>
                    <span className="table-data">R$ {rowData.valorEmpenho != undefined ? rowData.valorEmpenho.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span>
                </p>
            </React.Fragment>
        );
    }

    const valorLiquidadoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Valor Liquidado</span>
                    <span className="table-data">R$ {rowData.valorLiquidado != undefined ? rowData.valorLiquidado.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "0,00"}</span>
                </p>
            </React.Fragment>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleEditClick(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-mr-2 p-button-danger" loading={isDeleting} onClick={() => handleDeleteClick(rowData)} />


            </div>
        );
    }

    const onSort = (event) => {
        setSortableCampo(event.sortField);
        if (sortable === 'ASC') {
            setSortable('DESC')
        } else if (sortable === 'DESC') {
            setSortable('ASC')
        }
    }

    const handleEditClick = (data) => {

        history.push(`/empenho/edit/${data.id}`);

    }

    const handleDeleteClick = (data) => {

        data['userEmail'] = user != null ? user.email : ""

        confirmDialog({
            message: `Você tem certeza que quer remover o empenho?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: async () => {
                setIsDeleting(true);

                try {

                    const response = await api.delete(`empenho/${data.id}`);

                    toast.current.show({ life: 5000, severity: 'success', summary: 'Remoção concluída!', detail: `o empenho foi removida com sucesso` });
                    getEmpenho()
                }
                catch (error) {
                    toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover o empenho', detail: error.toString() });
                }

                setIsDeleting(false);
            },
            reject: () => { return }
        });
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    return (
        <>
            {(!filter || empenho.length > 0) &&                
            
            <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

                <Toast ref={toast} position="bottom-right" />

                <Toast ref={confirmationToast} position="bottom-center" />

                <FormUpload visible={visible} setVisible={setVisible} setLazyParams={setLazyParams} Titulo="Selecione o Arquivo de Empenhos" uploadTipo="0" />

                <div className="card p-col-12 datatable-responsive-demo">

                    <div className="p-fluid">
                        <DataTable
                            totalRecords={totalRecords}
                            lazy="true"
                            first={first}
                            onPage={getEmpenho}
                            header={header}
                            value={empenho}
                            filterDisplay="row"
                            className="p-datatable-responsive-demo"
                            emptyMessage="Nenhumo empenho encontrada"
                            loading={isLoading}
                            dataKey="id"
                            paginator
                            rows={20}
                            currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} empenhos`}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                            // rowsPerPageOptions={[50, 100]}
                            onSort={onSort}
                            onFilter={onFilter}
                            filters={lazyParams.filters}
                        >

                            <Column
                                field="id"
                                header="Código"
                                sortable
                                body={idBodyTemplate}
                                lazyParams
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Pesquisar por Código"

                            />

                            <Column
                                field="secretariaNome"
                                header="Secretaria"
                                sortable
                                body={secretariaBodyTemplate}
                                lazyParams
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Pesquisar por Secretaria"

                            />

                            <Column
                                field="ficha"
                                header="Ficha"
                                sortable
                                body={fichaBodyTemplate}
                                lazyParams
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Pesquisar por Ficha"

                            />

                            <Column
                                field="fonte"
                                header="Fonte"
                                sortable
                                body={fonteBodyTemplate}
                                lazyParams
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Pesquisar por Fonte"

                            />

                            <Column
                                field="numeroEmpenho"
                                header="Empenho"
                                sortable
                                body={numeroEmpenhoBodyTemplate}
                                lazyParams
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Pesquisar pelo número do empenho"
                            />

                            <Column
                                field="dataEmissao"
                                header="Data Emissão"
                                sortable
                                body={dataEmissaoBodyTemplate}
                            />

                            {/* <Column
                                field="dataVigencia"
                                header="Data Vigência"
                                sortable
                                body={dataVigenciaBodyTemplate}
                            /> */}

                            <Column
                                field="fornecedorNome"
                                header="Fornecedor"
                                sortable
                                body={fornecedorBodyTemplate}
                                filter={fornecedorNome === "TODOS"}
                                filterMatchMode="contains"
                                filterPlaceholder="Pesquisar por Fornecedor"
                            />

                            <Column
                                field="valorTotal"
                                header="Valor Total"
                                sortable
                                body={valorTotalBodyTemplate}
                            />

                            <Column
                                field="valorLiquidado"
                                header="Valor Liquidado"
                                sortable
                                body={valorLiquidadoBodyTemplate}
                            />

                            {!filter &&

                                <Column
                                    header="Ações"
                                    body={actionBodyTemplate}

                                />
                            }

                        </DataTable>

                    </div>
                </div>
            </div>
            }

        </>
    );
}

import React from 'react';

import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';
import { GraficoRender } from '../../components/GraficoRender';

export default function GraficosPage() {

    return (
        <>           
            <MenuMain >
                <GraficoRender graficoNome="Fornecedor" graficoId={19} />
                <GraficoRender graficoNome="Instalação" graficoId={20} />
                <GraficoRender graficoNome="Secretaria" graficoId={21} />
            </MenuMain>
            <AppFooter />    
        </>
    );
}

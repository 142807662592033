import React from 'react';

import MenuMain from '../../MenuMain';
import { AppFooter } from '../../AppFooter';
import { TabelaEmpenho } from '../../components/TabelaEmpenho';

export default function EmpenhoPage() {

    return (
        <>           
            <MenuMain >
                <TabelaEmpenho filter = {false} />
            </MenuMain>
            <AppFooter />    
        </>
    );
}
